import { all, call, put, select } from 'redux-saga/effects'

import { updateFlagOpenings } from '../../../api/projects-api'
import { FlagOpeningLinksAPIUpdatePayload, NormalizedFlag } from '../../../models/flags'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import { Flag, Workspace } from '../../lib/toolBoxes/2D'
import { selectCoordinatesToUpdate } from '../../slices/2D'
import { selectMaterialFlags, updateFlagOpeningCoordinates } from '../../slices/materialFlags'
import { IMUP2DCoordinatesToUpdate, Vector2D } from '../../types'

export function* redrawFlagsOnUpdate(openingId: number) {
    const coordinates: IMUP2DCoordinatesToUpdate | null = yield select(selectCoordinatesToUpdate)

    try {
        if (coordinates) {
            const paperManager = yield call(managers.get2DManager)
            const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)
            const flagTool: Flag = yield call(paperManager.getTool, Flag.NAME)

            const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

            const drawableAreaItems: paper.Item[] = yield call(
                workspaceTool.getItemsWithCriteria,
                'data',
                (data) => data.drawable_id === openingId && data.shapeType === DRAWING_TYPES.AREA
            )

            // TODO: instead center coordinates get the coordinates on the material
            const drawableCenterCoordinates: Vector2D = yield call(
                flagTool.getFlagPointFromCoordinates,
                coordinates.coordinates,
                !!drawableAreaItems.length
            )

            // prepare data to be sent to backend
            const preparedUpdateOpeningLinkData: FlagOpeningLinksAPIUpdatePayload[] = []

            flags.forEach((flag) => {
                flag.opening_links.forEach((link) => {
                    if (link.opening_id === openingId) {
                        preparedUpdateOpeningLinkData.push({
                            id: link.id,
                            flag_id: flag.id,
                            coordinates: drawableCenterCoordinates,
                        })
                    }
                })
            })

            // get flag ids to remove them from blueprint
            const flagIdsToRemove: Set<number> = yield new Set(
                preparedUpdateOpeningLinkData.map((flag) => flag.flag_id)
            )

            if (!flagIdsToRemove.size) return

            // get flags to remove
            const temporaryFlagItems: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
                flagIdsToRemove.has(data?.flag_id)
            )

            // remove flags
            yield all(temporaryFlagItems.map((item) => call(item.remove.bind(item))))

            // api call to update coordinates for opening_links
            yield call(updateFlagOpenings, { opening_links: preparedUpdateOpeningLinkData })

            // update coordinates in flag opening_links
            yield put(updateFlagOpeningCoordinates({ newOpeningLinkData: preparedUpdateOpeningLinkData }))

            const flagsToRedraw = flags.reduce<
                {
                    flag_id: number
                    order: number
                    type: string | null
                }[]
            >((acc, flag) => {
                if (flagIdsToRemove.has(flag.id)) {
                    acc.push({
                        flag_id: flag.id,
                        order: flag.order,
                        type: flag.type?.name || null,
                    })
                }

                return acc
            }, [])

            // re-draw flags based on updated material coordinates
            yield all(
                flagsToRedraw.map((link) =>
                    call(
                        flagTool.drawFlagByCoords,
                        drawableCenterCoordinates,
                        link.flag_id,
                        openingId,
                        link.order,
                        link.type,
                        false
                    )
                )
            )
        }
    } catch (error) {
        console.error(error)
    }
}
