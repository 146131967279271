import store from '../../../../stores'
import { updateFlagCoordinatesAction } from '../../../sagas/2D/updateFlagOpeningCoordinates'
import { resetActiveFlag, setActiveFlag } from '../../../slices/materialFlags'

function save2DFlagEdits() {
    return function saveFlag(this: paper.Item) {
        store.dispatch(
            updateFlagCoordinatesAction({ id: this.parent.data.flag_id, openingId: this.parent.data.openingId })
        )
    }
}

function select2DFlag(): () => void {
    return function selectFlag(this: paper.Item) {
        store.dispatch(setActiveFlag({ flag_id: this.parent.data.flag_id }))
    }
}

function deselect2DFlag(): () => void {
    return function deselectFlag(this: paper.Item) {
        store.dispatch(resetActiveFlag())
    }
}

export default function addSelectFunctionalityToFlag(path: paper.Item): void {
    path.data.onSelect = select2DFlag().bind(path)
    path.data.onDeselect = deselect2DFlag().bind(path)
    path.data.onSave = save2DFlagEdits().bind(path)
}
