import isNull from 'lodash/isNull'
import { distinctUntilChanged, takeWhile } from 'rxjs'

import { FloorLevelBreaklineTool } from '../floorLevelBreakline/FloorLevelBreakline.tool'
import Base from '../paperTool/PaperTool'
import { convertAbsoluteDistanceToFormattedString } from '../../../../../../components/markup/utils/helpers'
import { Coordinate } from '../../../../../../models/activeDrawable'
import { PRIMARY_200, PRIMARY_300 } from '../../../../../../shared/constants/colors'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import IndexableObject from '../../../../../../shared/constants/general-enums/indexableObject'
import {
    applyScaleFactorToPathArea,
    applyScaleFactorToPathLength,
    convertScaleFactorLabelEnumToDecimal,
} from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { BulkUpdateOpeningLocationCoordinatesPayload } from '../../../../../sagas/2D/bulkUpdateOpeningLocationCoordinates'
import {
    Cursors,
    EditHandleData,
    IMUP2DCoordinatesToUpdate,
    IMUPState,
    MidpointData,
    PaperToolConfig,
    TOOL_TYPE_ENUMS,
    TransformationTypes,
    VIEW_MODE,
} from '../../../../../types'
import { SNAPPING_TOLERANCE } from '../../../../../utils/constants'
import { getSnapPaths, getSnapPoint, getSnapPosition, pathsIntersect } from '../../../../utils/Snapping'

/**
 * Select.tool.tsx
 * Selects an item upon mouse click by default
 * Also contains functions to select all elements and move elements on drag
 */
export class Select extends Base {
    static NAME = 'SELECT'

    // Percentage of handle radius to use for crosshair length
    // Makes crosshair 50% of handle radius
    private static readonly HANDLE_CROSSHAIR_SIZE_SCALING = 0.5

    private static readonly MEASUREMENT_TOOLTIP_COLOR_HEX = '#000000'
    private static readonly MULTI_SELECT_RECTANGLE_OPACITY = 0.4 // 40%
    private static readonly DATA_ID_KEY = 'opening_location_id'
    private static readonly MULTISELECT_RECTANGLE_STROKE_WIDTH = 4
    private static readonly MULTISELECT_RECTANGLE_PADDING = 16

    private coordinates: IMUP2DCoordinatesToUpdate | null = null
    private editingActive = false
    private selectedItem: paper.PathItem | null = null
    private handles: paper.Group[] = []
    private midPoint: paper.Group | null = null
    private disableSelectTool = false
    private altSelectedItemIds: number[] | null = null
    protected snappingEnabled = false
    private activeGeometryGroupID: number | null = null
    private activeToolObjectId: number | null = null
    private activeHighlightId: number | null = null
    private multiSelectRectangle: paper.Item | null = null
    private multiSelectGroup: paper.Group | null = null
    private multiSelectGroupSelectorRectangle: paper.Item | null = null
    private multiSelectedItemParents: Record<number, paper.Item> | null = null

    private VERTEX_STROKE_COLOR = new this.paper.Color('black')
    private VERTEX_FILL_COLOR = new this.paper.Color('white')
    private MULTISELECT_FILL_COLOR = new this.paper.Color(PRIMARY_200)
    private MULTISELECT_STROKE_RECTANGLE_COLOR = new this.paper.Color(PRIMARY_300)

    private lengthStateParamToUse: 'width' | 'height' | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Select.NAME
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                distinctUntilChanged()
            )
            .subscribe((state) => {
                this.coordinates = state['2D'].coordinatesToUpdate
                this.editingActive = state['2D'].editingActive
                this.disableSelectTool = state['tools'].disableSelect
                this.altSelectedItemIds = state['2D'].altSelectedItems
                this.snappingEnabled = state['tools'].snappingEnabled
                this.activeGeometryGroupID = state['geometry'].activeGeometryGroupID
                this.activeToolObjectId = state['tools'].activeToolObjectId
                this.activeHighlightId = state['2D'].activeHighlightId
            })

        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.scaleHandles()
            })

        // subscribe to changes specifically in activeGeometryGroupID
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                distinctUntilChanged(
                    (prev, curr) => prev.geometry.activeGeometryGroupID === curr.geometry.activeGeometryGroupID
                )
            )
            .subscribe(() => {
                if (!this.activeGeometryGroupID) {
                    this.selectedItem = null
                }
            })

        // subscribe to changes specifically in activeToolObjectId
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                distinctUntilChanged((prev, curr) => prev.tools.activeToolObjectId === curr.tools.activeToolObjectId)
            )
            .subscribe(() => {
                if (!this.activeToolObjectId) {
                    this.selectedItem = null
                }
            })

        // subscribe to changes specifically in activeHighlightId
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                distinctUntilChanged((prev, curr) => prev['2D'].activeHighlightId === curr['2D'].activeHighlightId)
            )
            .subscribe(() => {
                if (!this.activeHighlightId) {
                    this.selectedItem = null
                }
            })
    }

    /**
     * Set the size of the handles by increasing their bounding boxes with new rectangles that are the target size.
     * This method is a work-around since Paper's scaling function only works with % scale factors. See: http://paperjs.org/reference/path/#scale-scale
     */
    private scaleHandles = () => {
        if (this.handles) {
            const targetDiameter = this.calculateHandleRadiusAtCurrentZoom() * 2

            this.handles.forEach((handle) => {
                const oldPosition = handle.position

                handle.bounds = new this.paper.Rectangle(
                    handle.bounds.topLeft,
                    new this.paper.Size(targetDiameter, targetDiameter)
                )
                handle.position = oldPosition
            })
        }
    }

    private createMultiSelectRectangle = (rectangle: paper.Path.Rectangle, startingPoint: paper.Point) => {
        rectangle.opacity = Select.MULTI_SELECT_RECTANGLE_OPACITY
        rectangle.fillColor = this.MULTISELECT_FILL_COLOR
        rectangle.data.shapeType = DRAWING_TYPES.AREA

        rectangle.data.startX = startingPoint.x
        rectangle.data.startY = startingPoint.y

        if (this.multiSelectRectangle) this.multiSelectRectangle.remove()

        this.multiSelectRectangle = rectangle
    }

    onMouseDrag = async (event: paper.ToolEvent): Promise<void> => {
        // Assign mouse position to the draggable point
        if (this.toolPanning(event) || this.disableSelectTool) {
            return
        }
        if (this.selectedItem) {
            this.selectedItem.data.state = TransformationTypes.MOVING

            if (this.selectedItem.data.isActive === false) {
                this.toggleItemOnClick(this.selectedItem)
            }
            !this.editingActive &&
                this.setState('2D', {
                    editingActive: true,
                })
            this.selectedItem.data.shapeHasChanged = true
            if (this.selectedItem.data.vertex) {
                this.moveVertex(this.selectedItem, event)
            } else if (this.selectedItem.data.state === TransformationTypes.MOVING) {
                this.moveItem(this.selectedItem, event)
            }
        } else if (this.multiSelectRectangle) {
            const startingPoint = new this.paper.Point(
                this.multiSelectRectangle.data.startX,
                this.multiSelectRectangle.data.startY
            )
            const newMultiSelectRectangle = new this.paper.Path.Rectangle(startingPoint, event.point)

            this.createMultiSelectRectangle(newMultiSelectRectangle, startingPoint)
        } else if (this.multiSelectGroup && this.multiSelectGroup.bounds.contains(event.point)) {
            this.multiSelectGroup.position = this.multiSelectGroup.position.add(event.delta)
            this.updateMultiSelectGroupSelectorRectangle()
            const bulkUpdateOpeningLocationCoordinatesData: BulkUpdateOpeningLocationCoordinatesPayload =
                this.multiSelectGroup.children.reduce((data, child) => {
                    const dataToUpdate = this.convertPaperItemToAPICoordinateModel(child, this.scaleFactor)

                    if (dataToUpdate.coordinates.length > 0) {
                        data.push({
                            coordinates: dataToUpdate.coordinates,
                            cutouts: dataToUpdate.cutouts,
                            measurements: dataToUpdate.measurements,
                            region_id: dataToUpdate.region_id,
                            opening_group_id: child.data.opening_group_id,
                            opening_id: child.data.drawable_id,
                            opening_location_id: child.data.opening_location_id,
                        })
                    }

                    return data
                }, [] as BulkUpdateOpeningLocationCoordinatesPayload)

            if (this.multiSelectGroup.children[0].data.onMultiSelectSave) {
                this.multiSelectGroup.children[0].data.onMultiSelectSave(bulkUpdateOpeningLocationCoordinatesData)
            }
        }
    }

    /**
     * Moves the entire PathItem (maintains dimensions and scale)
     * @param item
     * @param event
     */
    private moveItem = (item: paper.PathItem, event: paper.ToolEvent) => {
        if (
            item.data.drawing_type === DRAWABLE_TYPES.ROOF_SYSTEM ||
            item.data.drawing_type === DRAWABLE_TYPES.FLOOR_SYSTEM
        ) {
            // Adding sensibility tolerance for joist items as every change triggers api call
            if (Math.abs((event as any).event.movementX) > 2 || Math.abs((event as any).event.movementY) > 2) {
                item.position = item.position.add(event.point).subtract(event.lastPoint)
                this.updateAllHandles(item)
            }
        } else {
            if (this.snappingEnabled) {
                const items = this.paper.project.getItems({
                    data: (data) => data?.drawable_id || data?.aiSuggestion?.id,
                    id: (id: number) => id !== item.id,
                })

                const paths = getSnapPaths(this.paper, items)

                paths.forEach((path) => {
                    if (pathsIntersect(item as paper.Path, path, SNAPPING_TOLERANCE)) {
                        const calculatedSnapPosition = getSnapPosition(this.paper, path, item, SNAPPING_TOLERANCE)

                        if (!isNull(calculatedSnapPosition)) {
                            item.position = calculatedSnapPosition
                        }
                    }
                })
            }

            item.position = item.position.add(event.point).subtract(event.lastPoint)
            this.updateAllHandles(item)
        }
    }

    private getSiblingElement(vertex: paper.PathItem, baseItem: paper.PathItem): paper.Segment | null {
        if (
            baseItem instanceof this.paper.Path &&
            baseItem.data.shapeType === DRAWING_TYPES.SECTION &&
            baseItem.segments
        ) {
            if (baseItem.segments[vertex.data.position + 1]) {
                return baseItem.segments[vertex.data.position + 1]
            }

            return baseItem.segments[vertex.data.position - 1]
        }

        return null
    }

    private getNewPositionOnVertexMove(
        event: paper.ToolEvent,
        baseItem: paper.PathItem,
        siblingSegment: paper.Segment | null
    ): paper.Point {
        const isShiftKeyPressed = event['event'].shiftKey

        const items = this.paper.project.getItems({
            data: (data) => data?.drawable_id || data?.aiSuggestion?.id,
            id: (id: number) => id !== baseItem.id,
        })

        const eventPoint = this.snappingEnabled ? getSnapPoint(this.paper, items, event.point) : event.point

        if (
            isShiftKeyPressed &&
            baseItem instanceof this.paper.Path &&
            baseItem.data.shapeType === DRAWING_TYPES.SECTION &&
            siblingSegment
        ) {
            return this.calculateFixedAnglePoint(siblingSegment.point, eventPoint)
        }

        // break line should be resized only horizontally
        if (baseItem.data.toolName === FloorLevelBreaklineTool.NAME) {
            return new this.paper.Point([eventPoint.x, baseItem.position.y])
        }

        return eventPoint
    }

    /**
     * Moves a single point for a PathItem
     * @param vertex
     * @param event
     */
    private moveVertex(vertex: paper.PathItem, event: paper.ToolEvent) {
        const baseItem = vertex.data.parent

        const siblingSegment = this.getSiblingElement(vertex, baseItem)

        const newPosition = this.getNewPositionOnVertexMove(event, baseItem, siblingSegment)

        if (baseItem instanceof this.paper.Path) {
            vertex.position = newPosition
            vertex.nextSibling.position = newPosition
            baseItem.segments[vertex.data.position].point = newPosition

            // Only show distance tooltip for a line (2 segments -> 2 points)
            if (baseItem.segments.length === 2) {
                const firstPoint = baseItem.segments[0].point
                const coordinates: Coordinate[] = [
                    [firstPoint.x, firstPoint.y],
                    [newPosition.x, newPosition.y],
                ]

                this.mediator.mediate('common', {
                    tooltip: {
                        title: `Length: ${convertAbsoluteDistanceToFormattedString(
                            applyScaleFactorToPathLength({
                                pxValue: baseItem.length,
                                scaleFactor: this.scaleFactor,
                                dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                                xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                                yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                                coordinates: coordinates,
                                pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                            })
                        )}`,
                        visible: true,
                        color: Select.MEASUREMENT_TOOLTIP_COLOR_HEX,
                    },
                })
            } else if (baseItem.segments.length > 2) {
                this.mediator.mediate('common', {
                    tooltip: {
                        title: `Area: ${applyScaleFactorToPathArea({
                            pxValue: baseItem.area,
                            scaleFactor: this.scaleFactor,
                            dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                            xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                            yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                            pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                        }).toFixed(2)} sqft`,
                        visible: true,
                        color: Select.MEASUREMENT_TOOLTIP_COLOR_HEX,
                    },
                })
            }
        } else if (baseItem instanceof this.paper.CompoundPath) {
            // escape the movement of the vertex if it's out of bounds of the cutout's encompassing path
            if (vertex.data.cutoutIndex !== 0 && !baseItem.children[0].contains(newPosition)) return

            this.mediator.mediate('common', {
                tooltip: {
                    title: `Area: ${applyScaleFactorToPathArea({
                        pxValue: baseItem.area,
                        scaleFactor: this.scaleFactor,
                        dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                        xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                        yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                        pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                    }).toFixed(2)} sqft`,
                    visible: true,
                    color: Select.MEASUREMENT_TOOLTIP_COLOR_HEX,
                },
            })

            const allSegments = baseItem.children.flatMap((item) => {
                const path = item as paper.Path

                return path.segments
            })
            const oldPosition = allSegments[vertex.data.position].point // maintain old position in the case that the movement overlaps another cutout

            allSegments[vertex.data.position].point = newPosition // the position needs to be updated so that the geometry test can be done

            // iterate through the cutouts to perform the geometry tests
            for (let i = 0; i < baseItem.children.length; i++) {
                // make sure that the cutout being editing is tested
                if (baseItem.children[vertex.data.cutoutIndex].intersects(baseItem.children[i])) {
                    allSegments[vertex.data.position].point = oldPosition

                    return
                }
            }

            vertex.position = newPosition
            vertex.nextSibling.position = newPosition
        }
    }

    /**
     * Moves all the handles for a PathItem (primarily used when moving an entire PathItem) to match the item's vertices
     * @param item
     */
    private updateAllHandles(item: paper.PathItem) {
        if (item instanceof this.paper.Path) {
            const handles = this.handles

            handles.forEach((handle: paper.Group, i: number) => {
                handle.position = item.segments[i].point
            })
        } else if (item instanceof this.paper.CompoundPath) {
            const handles = this.handles
            const allSegments = item.children.flatMap((item) => {
                const path = item as paper.Path

                return path.segments
            })

            handles.forEach((handle: paper.Group, i: number) => {
                handle.position = allSegments[i].point
            })
        }
    }

    /**
     * Generates the handles for a PathItem
     * handles = UI elements that are used to visualize & edit vertex points
     * @param item
     */
    private addHandles = (item: paper.PathItem) => {
        if (item instanceof this.paper.Path) {
            item.segments.forEach((vertex, i) => {
                const handleData: EditHandleData = {
                    vertex: true,
                    parent: item,
                    position: i,
                    ignoreHitFilter: true,
                }
                const handle = this.buildHandle(vertex.point, handleData)

                this.handles.push(handle)
            })
        } else if (item instanceof this.paper.CompoundPath) {
            let segmentIndex = 0 // this is the overall index of the segment within the compound path

            item.children.forEach((subPath, subPathIndex) => {
                const path = subPath as paper.Path

                path.segments.forEach((vertex) => {
                    const handleData: EditHandleData = {
                        vertex: true,
                        parent: this.selectedItem!, // it can't be null here, but the compiler thinks it can be
                        position: segmentIndex,
                        cutoutIndex: subPathIndex, // denotes a cutout vertex
                        ignoreHitFilter: true,
                    }

                    const handle = this.buildHandle(vertex.point, handleData)

                    this.handles.push(handle)
                    segmentIndex++
                })
            })
        }
    }

    /**
     *
     * @param point vertex point for the
     * @param handleData object for the data for the handle
     * @returns
     */
    private buildHandle = (point: paper.Point, handleData: EditHandleData): paper.Group => {
        const handle = new this.paper.Group()
        const handleRadius = this.calculateHandleRadiusAtCurrentZoom()

        handle.addChild(
            new this.paper.Path.Circle({
                center: point,
                strokeColor: this.VERTEX_STROKE_COLOR,
                fillColor: this.VERTEX_FILL_COLOR,
                radius: handleRadius,
                opacity: 0.6,
                strokeScaling: false,
                data: handleData,
            })
        )

        handle.data = handleData

        const crossHairSize = handleRadius * Select.HANDLE_CROSSHAIR_SIZE_SCALING

        handle.addChild(this.constructCrosshairMarker(point, crossHairSize))
        handle.bringToFront()

        return handle
    }

    private handleMidpointClick = (event) => {
        this.removeMidpoint()

        const pathToDivide: paper.Path =
            event.target.data.parent instanceof this.paper.CompoundPath
                ? event.target.data.curve.path
                : (event.target.data.parent as paper.Path)

        // curve location takes a `time` param that is equivalent to the % (0-1) through the curve. So 0.5 would be the midpoint of the curve.
        pathToDivide.divideAt(new this.paper.CurveLocation(event.target.data.curve, 0.5))

        this.removeHandles()
        this.addHandles(event.target.data.parent)
        this.setState('2D', {
            editingActive: true,
        })
        this.saveGeometry()
    }

    private buildMidpoint = (point: paper.Point, midpointData): paper.Group => {
        // TODO: rename to midpoints
        const midpoint = new this.paper.Group()
        const midpointRadius = this.calculateHandleRadiusAtCurrentZoom()

        midpoint.addChild(
            new this.paper.Path.Circle({
                center: point,
                strokeColor: new this.paper.Color('black'),
                fillColor: new this.paper.Color('black'),
                radius: midpointRadius * 0.5,
                opacity: 1,
                strokeScaling: false,
                data: midpointData,
            })
        )

        midpoint.data = midpointData // attach the metadata
        midpoint.onClick = this.handleMidpointClick // bind the handler method
        midpoint.bringToFront() // bring the item to front so it's not hidden

        return midpoint
    }

    private extractCoordinatesToUpdateAndUpdate = (itemToUpdate: paper.Item) => {
        const dataToUpdate = this.convertPaperItemToAPICoordinateModel(itemToUpdate, this.scaleFactor)
        const updatedCoordinates = dataToUpdate.coordinates
        const updatedCutouts = dataToUpdate.cutouts

        // test if the coordinates have changed
        if (updatedCoordinates.toString() !== this.coordinates?.toString() && itemToUpdate.data.onSave) {
            const updatePayload: IMUP2DCoordinatesToUpdate = {
                coordinates: updatedCoordinates,
                measurements: dataToUpdate.measurements,
                cutouts: updatedCutouts,
            }

            itemToUpdate.data.onSave(updatePayload)
        }
    }

    /**
     * Save updated coordinates to the store if
     * - drawable has been selected
     * - dragging action for vertices is tracked
     * - new coordinates are different from the previous ones
     */
    private saveGeometry = () => {
        if (this.selectedItem && this.editingActive) {
            // make sure the drawable geometry is the one being edited, not the handles
            const itemToUpdate =
                this.selectedItem.data.vertex || this.selectedItem.data.midpoint
                    ? this.selectedItem.data.parent
                    : this.selectedItem

            this.extractCoordinatesToUpdateAndUpdate(itemToUpdate)
        }
    }

    private updateMultiSelectGroupSelectorRectangle = () => {
        if (this.multiSelectGroup) {
            const selectedRectangle = new this.paper.Path.Rectangle(
                this.multiSelectGroup.bounds.expand(Select.MULTISELECT_RECTANGLE_PADDING)
            )

            selectedRectangle.strokeColor = this.MULTISELECT_STROKE_RECTANGLE_COLOR
            selectedRectangle.strokeWidth = Select.MULTISELECT_RECTANGLE_STROKE_WIDTH
            if (this.multiSelectGroupSelectorRectangle) this.multiSelectGroupSelectorRectangle.remove()
            this.multiSelectGroupSelectorRectangle = selectedRectangle
        }
    }

    private createAndEmitMultiSelectGroup = () => {
        if (this.multiSelectRectangle) {
            this.createMultiSelectGroup()
            if (this.multiSelectGroup && this.multiSelectGroup.children.length === 0) {
                if (this.multiSelectRectangle) {
                    this.multiSelectGroup!.remove()
                    this.multiSelectGroup = null
                }
            }

            if (this.multiSelectGroup) {
                this.updateMultiSelectGroupSelectorRectangle()
            }

            if (this.multiSelectRectangle) {
                this.multiSelectRectangle.remove()
                this.multiSelectRectangle = null
            }

            this.mediator.mediate('2D', { multiSelectedDrawableIds: this.calculateDrawableIdsOfMultiSelectedItems() })
        }
    }

    onMouseUp = (): void => {
        if (this.currentActiveCursor !== Cursors.AUTO) {
            this.mediator.mediate('common', { cursor: Cursors.AUTO })
        }

        this.mediator.mediate('common', {
            tooltip: { title: '', visible: false, color: Select.MEASUREMENT_TOOLTIP_COLOR_HEX },
        })

        this.saveGeometry()
        this.createAndEmitMultiSelectGroup()
    }

    /**
     * Remove all the handles from the plan
     */
    removeHandles = () => {
        // need to remove children of the group so that it is destroyed within the paperscope
        // only using remove just dereferences the group from the parent, but doesn't remove it from the paperscope
        this.handles.forEach((vertex) => {
            vertex.remove()
        })
        this.handles = []

        // make sure all vertices are removed
        this.paper.project.activeLayer.children
            .filter((ch) => ch.data.vertex)
            .forEach((t) => {
                t.removeChildren()
            })
    }

    private removeHandleFromItem(item: paper.Item): void {
        // remove handle from UI
        this.handles.filter((handle) => handle.data.parent.id === item.id).forEach((vertex) => vertex.remove())

        // update the state
        this.handles = this.handles.filter((handle) => handle.data.parent.id !== item.id)
    }

    /**
     * Remove any midpoint from the plan
     */
    removeMidpoint = () => {
        if (this.midPoint) {
            this.midPoint.remove()
            this.midPoint = null
        }
    }

    private selectItemForEditing = (item: paper.Item): void => {
        if (this.selectedItem && item.data.vertex) {
            this.selectedItem = item as paper.PathItem
        } else {
            // if the selected item is not a Path, revert to the non-editing selection sequence
            // We also do not want to edit NOTE types (but they are not locked because we need to hover on them)
            if (!(item instanceof this.paper.PathItem)) {
                this.removeHandles()

                return
            } else if (item.data.drawing_type === DRAWABLE_TYPES.NOTE) {
                return
            }

            this.selectedItem = item as paper.Path

            // make sure that old vertices are removed if a new selection is being made while another item is currently selected
            if (this.handles.length) {
                this.removeHandles()
            }

            // only draw points if there are not any already AND if the shape is NOT a point AND if the shape is not a comment
            if (
                !this.handles.length &&
                item.data.shapeType !== DRAWING_TYPES.POINT &&
                item.data.drawing_type !== DRAWABLE_TYPES.NOTE
            ) {
                this.addHandles(this.selectedItem)
            }
        }
    }

    protected toggleItemOnClick = (item: paper.Item): void => {
        if (!item.data.vertex) {
            this.selectedItem = item as paper.PathItem

            // on element click, add isSelected to know which element is currently selected in blueprint
            this.selectedItem.data.isSelected = true

            // For regions, we click on the path, but the region data is on the parent group,
            // Therefore, we set the scale to the properties of the region group if a region path is selected
            const scale = item.data.scale ?? item.parent.data.scale

            if (scale && scale !== '') {
                this.setState('2D', { scaleFactor: convertScaleFactorLabelEnumToDecimal(scale) })
            }

            this.selectedItem.data.ignoreHitFilter = true
            this.selectedItem.data.onSelect()

            if (this.selectedItem.data.selectable !== false) {
                this.selectItemForEditing(this.selectedItem)
            } else {
                this.removeHandles()
            }
        }
    }

    /**
     * Hit test to see if a midpoint should be drawn
     * Returns boolean of whether the midpoint should be drawn
     */
    drawMidpoint = (event: paper.ToolEvent): void => {
        this.removeMidpoint()
        const hitRadius = this.calculateHandleRadiusAtCurrentZoom() // set tolerance to be the width of a handle

        const hitTestItem = this.selectedItem!.data.vertex ? this.selectedItem?.data.parent : this.selectedItem
        const hitTestResult = hitTestItem.hitTest(event.point, {
            tolerance: hitRadius * 2,
            fill: true,
            stroke: true,
        })
        // determine if the result is a valid item

        if (hitTestResult && this.selectedItem !== null) {
            // getting here means that the hit item is a segment already
            let point: paper.Point | null = null

            let midPointData: MidpointData | null = null

            if (hitTestResult.type === 'fill') {
                // remove the old midpoint if it exists
                this.removeMidpoint()

                // if the hit test is outside the bounds, exit the function
                if (
                    (hitTestResult.item as paper.Path).getNearestPoint(event.point).getDistance(event.point) > hitRadius
                ) {
                    return
                }

                const curve = (hitTestResult.item as paper.Path).getNearestLocation(event.point).curve

                point = curve.getPointAtTime(0.5) // get the midpoint of the curve
                midPointData = {
                    midpoint: true,
                    ignoreHitFilter: true,
                    parent: hitTestResult.item,
                    location: hitTestResult.location,
                    curve,
                    index: curve.index,
                }
            } else if (!this.midPoint) {
                point = hitTestResult.location.curve.getPointAtTime(0.5)

                midPointData = {
                    midpoint: true,
                    ignoreHitFilter: true,
                    parent: hitTestResult.item,
                    curve: hitTestResult.location.curve,
                    location: hitTestResult.location,
                    index: hitTestResult.location.curve.index,
                }
            }

            if (point && midPointData) {
                this.midPoint = this.buildMidpoint(point, midPointData)
            }
        }
    }

    /**
     * Toggles the selection of the given item in the altSelectedItems array.
     * If the item is already selected, it will be removed; otherwise, it will be added.
     *
     * @param item - The paper.js item to select or deselect
     */
    private handleAltSelect = (item: paper.Item): void => {
        let altSelectedItems = this.altSelectedItemIds ? [...this.altSelectedItemIds] : []

        if (altSelectedItems.includes(item.id)) {
            altSelectedItems = altSelectedItems.filter((id) => id !== item.id)
            this.removeHandleFromItem(item)
        } else {
            altSelectedItems.push(item.id)
            this.addHandles(item as paper.PathItem)
        }

        this.mediator.mediate('2D', { altSelectedItems })
    }

    onMouseMove = (event: paper.ToolEvent): void => {
        if (this.disableSelectTool || this.isPanningClick(event)) return

        const isMeasureTool =
            this.selectedItem?.data?.toolName === TOOL_TYPE_ENUMS.MEASUREMENT ||
            this.selectedItem?.data?.parent?.data?.toolName === TOOL_TYPE_ENUMS.MEASUREMENT

        // gatekeep the hit test behind reasonable logic since it's an expensive operation
        if (
            this.selectedItem &&
            !isMeasureTool && // do not display midpoint for measure tool
            this.handles &&
            this.handles.length
        ) {
            this.drawMidpoint(event)
        }
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event) || this.disableSelectTool) return

        const hit = this.getPaperScope().project.hitTest(event.point)
        const hitNothing = !hit || (!hit.item?.data.midpoint && !hit.item?.['_callbacks'])

        this.setState('2D', {
            editingActive: false,
        })

        const altKey = ((event as unknown as any).event as MouseEvent).altKey

        if (altKey && hit?.item) {
            this.handleAltSelect(hit.item)

            return
        }

        // prevent click on vertex if there are multiple items selected
        if (hit?.item?.data?.vertex && this.altSelectedItems?.length) {
            return
        }

        if (hit && hit.item && hit.item.data.onSelect) {
            const shouldPreventToggleItem = this.isShouldPreventToggleItem(hit)

            // prevent select item with different opening group id
            if (shouldPreventToggleItem) return

            if (this.multiSelectGroup) {
                this.clearAllMultiSelections()
            }

            // This call will trigger the saga which activates the handles via the selectItem method
            this.toggleItemOnClick(hit.item)
        } else if (hit && hit.item && hit.item.data.vertex) {
            this.selectItemForEditing(hit.item)
        } else if (hitNothing && !this.selectedItem && !this.multiSelectGroup && this.isMasterSetsEnabled) {
            const rectangle = new this.paper.Path.Rectangle(event.point, event.point)

            this.createMultiSelectRectangle(rectangle, event.point)
        } else if (
            hitNothing &&
            !this.selectedItem &&
            this.multiSelectGroup &&
            !this.multiSelectGroup.bounds.contains(event.point) &&
            this.isMasterSetsEnabled
        ) {
            this.clearAllMultiSelections()
        } else if (hitNothing && this.selectedItem) {
            this.exitSelectMode()
            this.selectedItem = null

            this.mediator.mediate('2D', { altSelectedItems: null })
        }
    }

    cancel = () => {
        this.exitSelectMode()
    }

    /**
     * When this.selectedItem is empty or the selectedItem opening_group_id, it's equal to hit item opening_group_id
     * it's mean that is the same material group and we should allow select, in all other cases we should prevent,
     * when snapping enabled, we see other group materials, other tools e.g., break line tool
     * @param hit
     */
    private isShouldPreventToggleItem = (hit: paper.HitResult): boolean => {
        return !(
            !this.selectedItem ||
            this.selectedItem?.data.opening_group_id === hit?.item?.data?.opening_group_id ||
            this.selectedItem?.data.opening_group_id === hit?.item?.data?.parent?.data?.opening_group_id
        )
    }

    public clearAllMultiSelections = (updateStore = true) => {
        try {
            this.multiSelectRectangle?.remove()
            this.multiSelectRectangle = null
            const children = [...(this.multiSelectGroup?.children || [])]

            if (this.multiSelectGroup && Boolean(this.multiSelectedItemParents)) {
                children?.forEach((item) => {
                    const parent = this.multiSelectedItemParents![item.id]

                    parent.addChild(item)
                    if (parent.parent !== null) {
                        item.sendToBack()
                    } else if (item.data.shapeType === DRAWING_TYPES.AREA) {
                        this.sendItemToBackBeforeRaster(item)
                    }
                })
            }
            this.multiSelectGroup?.remove()
            this.multiSelectGroup = null
            this.multiSelectGroupSelectorRectangle?.remove()
            this.multiSelectGroupSelectorRectangle = null
        } catch (_) {
            // We will catch this error in the case that
            // we have switched pages and dumped data
            // the data from multi selection
            // in that case drop the references
            // to the old data and let it get
            // cleaned up
            this.multiSelectRectangle = null
            this.multiSelectGroup = null
            this.multiSelectGroupSelectorRectangle = null
        }

        this.multiSelectedItemParents = []
        if (updateStore) this.mediator.mediate('2D', { multiSelectedDrawableIds: [] })
    }

    public exitSelectMode = (): void => {
        this.clearSelections(true)
    }

    public clearSelections = (shouldClearMultiSelection = false) => {
        this.lengthStateParamToUse = null
        const pathItem = this.selectedItem?.data.onDeselect ? this.selectedItem : this.selectedItem?.data.parent

        if (pathItem?.data) {
            pathItem.data.ignoreHitFilter = false
            pathItem.data?.onDeselect && pathItem.data?.onDeselect()
        }

        this.removeHandles()
        this.removeMidpoint()

        // on exit select mode, remove isSelected on every selected element on blueprint
        this.unSelectItems()
    }

    /**
     * Function gets all selected elements and unselect them,
     * used only to get an element from blueprint
     */
    public unSelectItems = () => {
        const allSelectedUIItems = this.paper.project.getItems({
            data: (data) => data.isSelected,
        })

        if (allSelectedUIItems.length) {
            allSelectedUIItems.forEach((item) => (item.data.isSelected = false))
        }
    }

    public getSelectedItem = () => {
        return this.selectedItem
    }

    public calculateDrawableIdsOfMultiSelectedItems = (): number[] => {
        if (isNull(this.multiSelectGroup)) return []

        return Array.from(
            this.multiSelectGroup.children
                .reduce((ids, child) => {
                    ids.add(child.data[Select.DATA_ID_KEY])

                    return ids
                }, new Set<number>())
                .values()
        )
    }

    private createMultiSelectGroup = () => {
        if (Boolean(this.multiSelectGroup) || isNull(this.multiSelectRectangle)) return

        const multiSelectGroup = new this.paper.Group()

        this.multiSelectedItemParents = {}

        this.paper.project
            .getItems({
                data: (data: IndexableObject | undefined) => data?.[Select.DATA_ID_KEY] && data.isMultiSelectable,
            })
            .forEach((item) => {
                if (
                    item.visible &&
                    (item.intersects(this.multiSelectRectangle!) || item.isInside(this.multiSelectRectangle!.bounds))
                ) {
                    this.multiSelectedItemParents![item.id] = item.parent
                    multiSelectGroup.addChild(item)
                }
            })

        if (multiSelectGroup.children.length !== 0) {
            this.multiSelectGroup = multiSelectGroup
        }
    }

    public removeItemsWithPredicateFromMultiSelect = (predicate: (item: paper.Item) => boolean) => {
        if (!this.multiSelectGroup) return

        const multiSelectedChildren = this.multiSelectGroup.children.filter((item) => predicate(item))

        this.multiSelectGroup.children.forEach((item) => {
            const parent = this.multiSelectedItemParents![item.id]

            parent.addChild(item)
            if (parent.parent !== null) {
                item.sendToBack()
            } else if (item.data.shapeType === DRAWING_TYPES.AREA) {
                this.sendItemToBackBeforeRaster(item)
            }
        })

        this.multiSelectGroup = new this.paper.Group(multiSelectedChildren)

        this.updateMultiSelectGroupSelectorRectangle()
    }

    public getMultiSelectedItems = () => {
        if (!this.multiSelectGroup) return [] as paper.Item[]

        return this.multiSelectGroup.children
    }

    public getMultiSelectedItemParent = (itemId: number) => {
        if (!this.multiSelectedItemParents) return null

        const potentialParent = this.multiSelectedItemParents[itemId]

        if (!potentialParent) return null

        return potentialParent
    }
}

export default Select
