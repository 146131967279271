import isUndefined from 'lodash/isUndefined'

import {
    BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS,
    BUILDING_FRAMING_PROJECT_MATERIALS_SETTINGS_LABELS,
    BUILDING_SETTING_KEYS,
    buildingSettingsValidationText,
    isSoffitWidthRequired,
    otherValue,
} from './constants'
import { FieldData, LumberSettingsPreparedFields, LumberSettingsPreparedResponse } from './models'
import { AzureFeatureFlag } from '../../../../models/azureFeatureFlags'
import { DropdownFieldOption } from '../../../../models/dropdownOptions'
import { Building, BuildingSettings } from '../../../../models/projectBuilding'
import { AzureFeatureFlagIds } from '../../../../shared/constants/azure-feature-flags'
import { DRAWABLE_TYPES } from '../../../../shared/constants/drawable-types'
import { ProjectPackages } from '../../../../shared/constants/project-packages'
import { isAzureFeatureFlagEnabled } from '../../../../shared/services/azure-feature-flag-services/azure-feature-flag-services'
import { capitalizeEachWord } from '../../../../utils/stringFormatters'

/**
 * Function generates and returns a set of default options for lumber fields based on
 * the provided material type and key.
 * It adjusts specific fields like species and thickness depending on the material type,
 * using different species from the response data (res).
 *
 * @param material
 * @param key
 * @param res
 * @param azureFeatureFlags
 */
export const getLumberFieldsOptions = (
    material: DRAWABLE_TYPES,
    key: string,
    res: LumberSettingsPreparedResponse,
    azureFeatureFlags: AzureFeatureFlag[] | null
): LumberSettingsPreparedFields => {
    const defaultFields: LumberSettingsPreparedFields = {
        partName: key,
        species: res.species_base,
        grade: res.grade,
        thickness: res.thickness_base,
        dry: false,
        fire_rated: false,
        finger_jointed: false,
    }

    const eaveGableLengthExtended1Species = ['fascia_boards_1', 'fascia_boards_2', 'ladder_framing', 'bracing']

    // add treated as null to display dash for each lumber setting row
    if (isAzureFeatureFlagEnabled(azureFeatureFlags, AzureFeatureFlagIds.robust_overhang)) {
        defaultFields.treated = null
    }

    switch (material) {
        case DRAWABLE_TYPES.EAVE_LENGTH:
            if (
                (key === 'rafter_tail' || key === 'blocking') &&
                isAzureFeatureFlagEnabled(azureFeatureFlags, AzureFeatureFlagIds.robust_overhang)
            ) {
                // add default treated value
                return { ...defaultFields, treated: false }
            }

            if (eaveGableLengthExtended1Species.includes(key)) {
                return { ...defaultFields, species: res.species_extended_1 }
            }

            return defaultFields

        case DRAWABLE_TYPES.GABLE_LENGTH:
            if (
                key === 'rafter_tail' &&
                isAzureFeatureFlagEnabled(azureFeatureFlags, AzureFeatureFlagIds.robust_overhang)
            ) {
                // add default treated value
                return { ...defaultFields, treated: false }
            }

            if (eaveGableLengthExtended1Species.includes(key)) {
                return { ...defaultFields, species: res.species_extended_1 }
            }

            return defaultFields

        case DRAWABLE_TYPES.DROPPED_BEAM:
        case DRAWABLE_TYPES.FLUSH_BEAM:
        case DRAWABLE_TYPES.RIDGE_BEAM:
        case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
        case DRAWABLE_TYPES.HEADER:
            return { ...defaultFields, species: res.species_extended_2 }

        case DRAWABLE_TYPES.LEDGER:
            return { ...defaultFields, species: res.species_extended_1 }

        case DRAWABLE_TYPES.BLOCKING:
            return { ...defaultFields, species: res.species_extended_1 }

        case DRAWABLE_TYPES.POST:
            // set to null to keep table cell visible
            defaultFields.thickness = null

            return defaultFields

        case DRAWABLE_TYPES.ROOF_SYSTEM:
            return { ...defaultFields, species: res.species_extended_2 }

        case DRAWABLE_TYPES.WALL:
        case DRAWABLE_TYPES.FLOOR_SYSTEM:
        default:
            return defaultFields
    }
}

// TODO: should be removed when BE set all default fields
export const getDefaultFieldValue = (options: DropdownFieldOption[]): string => {
    const defaultData = options.find(({ is_default }) => is_default)

    return defaultData ? defaultData.value : ''
}

export const prepareErrorMessage = (fieldName: string, validationType: buildingSettingsValidationText): string => {
    return `${capitalizeEachWord(fieldName.replaceAll('_', ' '))} ${validationType}`
}

export const isNumberIsBetween0And99 = (value: string): boolean => {
    const regex = /^(0|[1-9][0-9]?)$/

    return regex.test(value)
}

/**
 * Function checks if the "thickness" property is either missing or empty in lumberSettings object,
 * while other properties are set.
 * If such a case exists, it returns true, otherwise, it returns false
 *
 * @param lumberSettings
 */
export const buildingLumberSettingsValidation = (
    lumberSettings: BuildingSettings['lumber_settings']
): string | null => {
    let errorMessage: string | null = null

    if (lumberSettings && !!Object.keys(lumberSettings)?.length) {
        Object.keys(lumberSettings).forEach((material) => {
            Object.keys(lumberSettings[material]).forEach((type) => {
                // post do not have thickness
                if (DRAWABLE_TYPES.POST === material) return

                const rowData = lumberSettings[material][type]

                // check if thickness is empty or isn't set and some other field is set
                const isSomeKeySetExcludingThickness =
                    (rowData.thickness === '' || !('thickness' in rowData)) &&
                    Object.entries(rowData).some(
                        ([key, value]) =>
                            key !== 'thickness' &&
                            ((typeof value === 'string' && value !== '') || (typeof value === 'boolean' && value))
                    )

                if (isSomeKeySetExcludingThickness) {
                    errorMessage = prepareErrorMessage(
                        `${material} ${type} Thickness`,
                        buildingSettingsValidationText.required
                    )
                }
            })
        })
    }

    return errorMessage
}

/**
 * Function checks if the "soffit_tg_width"
 * property is required based on the "soffit_type" in the exteriorSettings object.
 * It returns true if "soffit_type" requires a width and "soffit_tg_width" is not set; otherwise, it returns false.
 *
 * @param exteriorSettings
 */
export const buildingExteriorSettingsValidation = (
    exteriorSettings: BuildingSettings['exterior_settings']
): string | null => {
    const soffitType = exteriorSettings?.soffit_type

    if (
        soffitType &&
        isSoffitWidthRequired?.includes(soffitType?.toLowerCase()) &&
        !exteriorSettings?.soffit_tg_width
    ) {
        return prepareErrorMessage(
            BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.soffit_tg_width,
            buildingSettingsValidationText.required
        )
    }

    if (exteriorSettings.eave_width === otherValue || exteriorSettings?.eave_width_other) {
        // validate if eave_width_other is empty
        if (!exteriorSettings.eave_width_other) {
            return prepareErrorMessage(
                BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.eave_width_other,
                buildingSettingsValidationText.required
            )
        }

        // validate if eave_width_other between 0 and 99
        if (exteriorSettings.eave_width_other && !isNumberIsBetween0And99(exteriorSettings.eave_width_other)) {
            return prepareErrorMessage(
                BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.eave_width_other,
                buildingSettingsValidationText.between_0_99
            )
        }
    }

    // validate if other gable_width_other is not empty
    if (exteriorSettings.gable_width === otherValue || exteriorSettings?.gable_width_other) {
        // validate if gable_width_other is empty
        if (!exteriorSettings.gable_width_other) {
            return prepareErrorMessage(
                BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.gable_width_other,
                buildingSettingsValidationText.required
            )
        }

        // validate if gable_width_other between 0 and 99
        if (exteriorSettings.gable_width_other && !isNumberIsBetween0And99(exteriorSettings.gable_width_other)) {
            return prepareErrorMessage(
                BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.gable_width_other,
                buildingSettingsValidationText.between_0_99
            )
        }
    }

    // validate if rafter_tail_size is not empty
    if (
        exteriorSettings?.rafter_tail &&
        exteriorSettings.rafter_tail !== 'NONE' &&
        !exteriorSettings.rafter_tail_size
    ) {
        return prepareErrorMessage(
            BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.rafter_tail_size,
            buildingSettingsValidationText.required
        )
    }

    // validate if eave_blocking_width is not empty
    if (
        exteriorSettings?.rafter_tail &&
        (exteriorSettings.rafter_tail === 'EAVES' || exteriorSettings.rafter_tail === 'EAVES & GABLES') &&
        !exteriorSettings.eave_blocking_width
    ) {
        return prepareErrorMessage(
            BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS.eave_blocking_width,
            buildingSettingsValidationText.required
        )
    }

    return null
}

/**
 * Validates the selection of a plate option based on the chosen project packages.
 * Returns an error message if the 'Comprehensive' package is selected and no valid plate option is provided.
 *
 * @param packagesSelected - Array of selected project package names.
 * @param plateOption - Selected plate option, can be a string, null, or undefined.
 *
 * @returns Error message if validation fails; otherwise, null.
 */
export const buildingPlateOptionsValidation = (packagesSelected: string[], plateOption: string | null | undefined) => {
    if (packagesSelected.includes(ProjectPackages.Comprehensive) && !plateOption && plateOption !== undefined) {
        return prepareErrorMessage(
            BUILDING_FRAMING_PROJECT_MATERIALS_SETTINGS_LABELS.plate_option,
            buildingSettingsValidationText.required
        )
    }

    return null
}

/**
 * Retrieves the setting value for a given key from the active building's settings based on the active tab.
 *
 * @param key - The setting key to retrieve.
 * @param activeBuilding - The current active building object or null.
 * @param buildingActiveTab - The active tab identifier or null.
 * @param options - Options that field have.
 *
 * @returns The setting value as a string, boolean, or undefined.
 */
export const getBuildingSettingsInputValue = (
    key: string,
    activeBuilding: Building | null,
    buildingActiveTab: string | null,
    options?: FieldData[]
): string | boolean | undefined => {
    const settingsObject =
        activeBuilding &&
        (BUILDING_SETTING_KEYS.WASTE_FACTORS === buildingActiveTab ||
            BUILDING_SETTING_KEYS.PROJECT_MATERIALS === buildingActiveTab)
            ? activeBuilding.settings?.framing_settings
            : activeBuilding?.settings

    const buildingSettingsValue =
        buildingActiveTab && settingsObject?.[buildingActiveTab] ? settingsObject[buildingActiveTab][key] : ''

    // options are pass only from field with other value
    if (isUndefined(options)) return buildingSettingsValue

    const foundValue = options.find(({ value }) => value === buildingSettingsValue)

    // in case we select something from options
    if (foundValue) {
        return buildingSettingsValue
    }

    // search for value for *_other field
    if (key.endsWith('_other')) {
        const mainField = key.replace(`_${otherValue}`, '')

        if (buildingActiveTab && settingsObject?.[buildingActiveTab]) {
            const mainFieldValue = settingsObject[buildingActiveTab]?.[mainField]

            // set the field_other with field value if it is not other
            if (buildingSettingsValue === null) {
                // in case we selected other for a field
                if (mainFieldValue !== otherValue) {
                    return mainFieldValue
                }

                return ''
            }

            return buildingSettingsValue
        }
    }

    if (!buildingSettingsValue) {
        return ''
    }

    // assume it's main field, just set the other
    return otherValue
}

/**
 * Checks if any value in the provided array matches the predefined "other" value.
 *
 * @param values - An array of FieldData objects to search.
 * @returns True if "other" value exists; otherwise, false.
 */
export const isOtherValueExists = (values: FieldData[]): boolean => {
    return values.some((fieldData) => fieldData.value?.toLowerCase() === otherValue)
}

export const valueIncludesCedarOrPine = (value: string) => {
    return value.toLowerCase().includes('cedar') || value.toLowerCase().includes('pine')
}

/**
 * Add building name before the error message to easily identify where error is
 *
 * @param buildingName
 * @param errorMessage
 */
export const addBuildingNameBeforeErrorMessage = (buildingName: string, errorMessage: string): string => {
    return `${buildingName}: ${errorMessage}`
}

export const removeOtherValues = (arr) => {
    return arr.map((item) => removeOtherValuesFromObject(item))
}

/**
 * Set field_other into field, and set to null field_other
 *
 * @param obj
 */
export const removeOtherValuesFromObject = (obj) => {
    const newObj = Array.isArray(obj) ? [] : {}

    for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            newObj[key] = removeOtherValuesFromObject(obj[key])
        } else {
            const otherKey = `${key}_other`

            if (obj.hasOwnProperty(otherKey)) {
                if (obj[key] !== otherValue) {
                    newObj[key] = obj[key]
                }

                if (obj[key] === otherValue && obj[otherKey] !== null) {
                    newObj[key] = obj[otherKey]
                }

                newObj[otherKey] = null
            } else if (!key.endsWith('_other')) {
                newObj[key] = obj[key]
            }
        }
    }

    return newObj
}
